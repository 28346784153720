import React from 'react'

const Heading = ({children, size, className, as}) => {

    let H = size ? size : 'h1'
    H = as ? as : H
    let sizeClasses = ''

    switch(size) {

        case 'h1':
            sizeClasses = `uppercase text-3xl lg:text-4.5xl font-black italic leading-9`;
            break;
        case 'h2':
            sizeClasses = `uppercase text-2xl lg:text-2.5xl font-black italic leading-7`;
            break;
        case 'h3':
            sizeClasses = `uppercase text-2xl lg:text-2xl font-black italic leading-6`;
            break;
        case 'h4':
            sizeClasses = `text-lg lg:text-lg font-black italic leading-6`;
            break;
        case 'h5':
            sizeClasses = `text-base lg:text-base font-black italic leading-6`;
            break;
        case 'h6':
            sizeClasses = `text-sm lg:text-sm font-black italic leading-5`;
            break;
        default:
            // Default to h1 sizes
            sizeClasses = `uppercase text-3xl lg:text-4.5xl font-black italic leading-9`;
    }
    let allClasses = `font-display mb-3 ${sizeClasses} ${className ? className : ""}`
    return <H class={`${allClasses}`}>{children}</H>
}

export default Heading