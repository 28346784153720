import React from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Button from "src/components/Button"
import { Link } from 'gatsby'
import SbEditable from 'storyblok-react'
import { useStaticQuery, graphql } from "gatsby"
import resolveLink from 'src/utils/resolveLink'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const HeaderDesktopItem = (item, heroColour) => {

    const { blogPosts } = useStaticQuery(graphql`
    query BlogPosts {
      blogPosts: allStoryblokEntry(
        filter: {field_component: {eq: "post"}}
        limit: 3
        sort: {fields: published_at, order: DESC}
      ) {
        edges {
          node {
            name
            full_slug
          }
        }
      }
    }
    `)

    return (
        <Popover className="relative">
            {({ open }) => (
            <>
                <Popover.Button
                as="div"
                className={classNames(
                    item.hasHero && !item.scrolled ? 'text-white' : 'text-darkblue',
                    open && `${item.heroColour === 'pink' ? '!text-darkblue' : '!text-pink'}`,
                    `cursor-pointer p-3 text-[0.9375rem] font-body font-semibold leading-none duration-300 group flex items-center focus:outline-none outline-none ${item.heroColour === 'pink' ? 'hover:text-darkblue' : 'hover:text-pink'}`
                )}
                >
                <span className={"leading-none"}>{item.name}</span>
                <ChevronDownIcon
                    className={classNames(
                    open ? '' : '',
                    'h-4 w-4 group-hover:text-gray-500'
                    )}
                    aria-hidden="true"
                />
                </Popover.Button>

                <Transition
                as={Fragment}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                >
                <Popover.Panel className={`rounded-md border border-[#ededed] absolute z-40 left-1/2 transform -translate-x-1/2 px-2 w-screen max-w-md sm:px-0 duration-300 ${item.scrolled ? 'mt-0' : 'mt-3'}`}>
                    <div className="rounded-md shadow-lg max-h-[85vh]">
                    <div className="rounded-t-md relative grid gap-4 bg-white px-5 py-6 sm:px-4 sm:py-8">
                        {item.children.map((item, index) => (
                            <SbEditable content={item} key={item._uid}>
                                <Link
                                    key={index}
                                    to={`${item.target.cached_url === "home" ? "" : resolveLink(item.target.cached_url)}`}
                                    className="-m-3 p-3 flex items-start group"
                                >
                                    <div className="ml-4">
                                        <p className="text-[0.9375rem] font-body font-semibold leading-5 text-darkblue group-hover:text-pink duration-300">{item.name}</p>
                                        <p className="text-sm text-gray font-body">{item.description}</p>
                                    </div>
                                </Link>
                            </SbEditable>
                        ))}
                    </div>
                    <div className="rounded-b-md px-5 py-5 bg-[#FBFBFB] sm:px-8 sm:pt-8 sm:pb-5">
                        <div>
                            <h3 className="text-[0.9375rem] font-body font-semibold leading-5 text-darkblue">Recent Posts</h3>
                            <ul className="mt-2 space-y-1">
                                {blogPosts.edges.map((item, index) => (
                                    <Link to={resolveLink(item.node.full_slug)} key={index} className="font-body text-gray hover:text-pink duration-300 block text-sm">
                                        {item.node.name}
                                    </Link>
                                ))}
                            </ul>
                        </div>
                        <div className="mt-5 text-sm">
                            <Button to="summit-blog" type="pink" arrow>See all posts</Button>
                        </div>
                    </div>
                    </div>
                </Popover.Panel>
                </Transition>
            </>
            )}
        </Popover>
    )
}

export default HeaderDesktopItem