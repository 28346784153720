const BackgroundColour = (props) => {
    switch(props) {
        case 'pink':
            return 'bg-pink text-white'
        case 'yellow':
            return 'bg-yellow text-pink'
        case 'lightblue':
            return 'bg-lightblue text-darkblue'
        case 'darkblue':
            return 'bg-darkblue text-white'
        case 'white':
            return 'bg-white text-gray'
        default:
            return 'bg-darkblue text-white'
    }
}

const TitleColour = (props) => {
    switch(props) {
        case 'pink':
            return 'text-yellow'
        case 'yellow':
            return 'text-darkblue'
        case 'lightblue':
            return 'text-darkblue'
        case 'darkblue':
            return 'text-pink'
        case 'white':
            return 'text-darkblue'
        default:
            return 'text-pink'
    }
}

const ButtonColour = (props, invert) => {
    switch(props) {
        case 'pink':
            return invert ? '!text-pink !bg-white hover:!text-pink hover:!bg-yellow' : '!text-pink !bg-yellow hover:!text-pink hover:!bg-white'
        case 'yellow':
            return invert ? '!text-pink !bg-white hover:!text-yellow hover:!bg-pink' : '!text-yellow !bg-pink hover:!text-pink hover:!bg-white'
        case 'lightblue':
            return invert ? '!text-darkblue !bg-white hover:!text-white hover:!bg-darkblue' : '!text-white !bg-darkblue hover:!text-darkblue hover:!bg-white'
        case 'darkblue':
            return invert ? '!text-pink !bg-white hover:!text-white hover:!bg-pink' : '!text-white !bg-pink hover:!text-pink hover:!bg-white'
        default:
            return invert ? '!text-darkblue !bg-white hover:!text-white hover:!bg-darkblue' : '!text-white !bg-pink hover:!text-pink hover:!bg-white'
    }
}

const ArrowButtonColour = (props, noHover) => {
    switch(props) {
        case 'pink':
            return noHover ? '!text-white' : '!text-white hover:!text-yellow'
        case 'yellow':
            return noHover ? '!text-pink' : '!text-pink hover:!text-darkblue'
        case 'lightblue':
            return noHover === true ? '!text-white' : '!text-white hover:!text-darkblue'
        case 'white':
            return noHover === true ? '!text-pink' : '!text-pink hover:!text-darkblue'
        case 'darkblue':
            return noHover ? '!text-white' : '!text-white hover:!text-pink'
        default:
            return noHover ? '!text-white' : '!text-white hover:!text-pink'
    }
}

const LinkColour = (props) => {
    switch(props) {
        case 'pink':
            return 'hover:text-yellow'
        case 'yellow':
            return 'hover:text-pink'
        case 'lightblue':
            return 'hover:text-white'
        case 'darkblue':
            return 'hover:text-pink'
        default:
            return 'hover:text-pink'
    }
}

const SliderArrowColour = (props) => {
    switch(props) {
        case 'pink':
            return 'bg-yellow text-pink'
        case 'yellow':
            return 'bg-darkblue text-white'
        case 'lightblue':
            return 'bg-darkblue text-white'
        case 'darkblue':
            return 'bg-pink text-white'
        default:
            return 'bg-darkblue text-white'
    }
}

const HrColour = (props) => {
    switch(props) {
        case 'pink':
            return 'border-yellow'
        case 'yellow':
            return 'border-darkblue'
        case 'lightblue':
            return 'border-darkblue'
        case 'darkblue':
            return 'border-pink'
        default:
            return 'border-pink'
    }
}

const ArrowColour = (props) => {
    switch(props) {
        case 'pink':
            return 'text-white group-hover:text-pink'
        case 'yellow':
            return 'text-white group-hover:text-darkblue'
        case 'lightblue':
            return 'text-white group-hover:text-darkblue'
        case 'darkblue':
            return 'text-white group-hover:text-pink'
        default:
            return 'text-white group-hover:text-pink'
    }
}

const LabelColour = (props) => {
    switch(props) {
        case 'darkblue':
            return 'text-white'
        case 'white':
            return 'text-pink'
        case 'lightblue':
            return 'text-white'
        case 'pink':
            return 'text-white'
        default:
            return 'text-pink'
    }
}

export {
    BackgroundColour,
    TitleColour,
    ButtonColour,
    ArrowButtonColour,
    LinkColour,
    SliderArrowColour,
    HrColour,
    ArrowColour,
    LabelColour
}