import React from 'react'
import { Link } from 'gatsby'
import resolveLink from 'src/utils/resolveLink'

const Button = ({ children, className, to, target, type, arrow, activeClassName, anchor }) => {

    const classes = (type, arrow) => {
        switch(type) {
            case 'pink':
                return arrow ? 'text-pink hover:text-lightblue pl-0' : 'text-white bg-pink hover:text-white hover:bg-lightblue pl-6'
            case 'yellow':
                return arrow ? 'text-yellow hover:text-pink pl-0' : 'text-pink bg-yellow hover:text-white hover:bg-pink pl-6'
            case 'darkblue':
                return arrow ? 'text-darkblue hover:text-lightblue pl-0' : 'text-white bg-darkblue hover:text-white hover:bg-lightblue pl-6'
            case 'lightblue':
                return arrow ? 'text-lightblue hover:text-darkblue pl-0' : 'text-white bg-lightblue hover:text-white hover:bg-darkblue pl-6'
            case 'white':
                return arrow ? 'text-white hover:text-darkblue pl-0' : 'text-darkblue bg-white hover:text-white hover:bg-darkblue pl-6'
            default:
                return arrow ? 'text-white hover:text-darkblue pl-0' : 'text-darkblue bg-white hover:text-white hover:bg-darkblue pl-6'
        }
    }
   
    return (
        to.includes('tel:') || to.includes('mailto:') ? 
          <a className={`font-display font-black text-lg leading-6 uppercase italic cursor-pointer inline-block pr-6 py-3 line-crop tracking-[0.03em] transition duration-300 ${classes(type, arrow)} ${className ? className : ""}`} href={to}>
              { arrow && <i className={`far fa-long-arrow-${arrow === 'reverse' ? 'left' : 'right'} pr-1 text-xl`}></i>} {children}
          </a>
        :
          <Link className={`font-display font-black text-lg leading-6 uppercase italic cursor-pointer inline-block pr-6 py-3 line-crop tracking-[0.03em] transition duration-300 ${classes(type, arrow)} ${className ? className : ""}`} to={to === 'home' ? '/' : anchor ? `${resolveLink(to)}#${anchor}` : resolveLink(to)} activeClassName={activeClassName}>
              { arrow && <i className={`far fa-long-arrow-${arrow === 'reverse' ? 'left' : 'right'} pr-1 text-xl`}></i>} {children}
          </Link>
    )
}

export default Button
