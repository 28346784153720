import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

const FixedCta = () => {

    const [scrolled, setScrolled] = useState(true)

    useEffect(() => {
      window.addEventListener("scroll", function () {
        // Old code if we ever need to revert
        // window.scrollY > 200 && window.scrollY + window.innerHeight < document.body.offsetHeight - 200
        if (window.scrollY > ((document.body.offsetHeight - window.innerHeight) - 200)) {
          setScrolled(false)
        } else {
          setScrolled(true)
        }
      })
    })

    return (
        <div className={`fixed bottom-0 flex justify-center transition duration-300 z-50 left-1/2 transform -translate-x-1/2 sm:w-auto w-full ${scrolled ? "translate-y-0" : "translate-y-full"}`}>
            <div className={`bg-pink`}>
                <span className="inline-block font-display uppercase italic font-black lg:!text-xl md:!text-lg xs:text-base text-sm md:!pl-6 xs:pl-4 pl-3 xs:py-3 py-2 leading-6 text-white">Call us on:</span><a href="tel:0845 052 3701" className="inline-block font-display uppercase italic font-black lg:!text-xl md:!text-lg xs:text-base text-sm pl-1 xs:pr-3 pr-2 xs:py-3 py-2 leading-6 text-white hover:text-white/90 transition duration-300">0845 052 3701</a>
            </div>
        </div>
    )
}

export default FixedCta