import React, { useState, useEffect } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuAlt1Icon, XIcon } from '@heroicons/react/outline'
import LogoDarkblue from '/static/summit_logo_darkblue.svg'
import LogoWhite from '/static/summit_logo_white.svg'
import Button from "src/components/Button"
import { Link } from 'gatsby'
import SbEditable from 'storyblok-react'
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import HeaderDesktopItem from './HeaderDesktopItem'
import HeaderMobileItem from './HeaderMobileItem'
import resolveLink from 'src/utils/resolveLink'

const Navigation = ({ settings, hasHero, heroColour, hasMargin }) => {

  const [scrollLock, setScrollLock] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [scrolled])

  // Change the style of the navbar on scroll
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  // https://github.com/tailwindlabs/tailwindcss/discussions/1610#discussioncomment-352836
  // gets screen size - to fix mobile viewport height problem
  useEffect(() => {
    if (typeof window !== 'undefined') {

        function handleResize() {
          let vh = window.innerHeight * 0.01
          document.documentElement.style.setProperty('--vh', `${vh}px`)
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
      }
  }, []);

  const Logo = !hasHero || scrolled ? LogoDarkblue : LogoWhite

  return (
    <SbEditable content={settings} key={settings._uid}>
        {!hasMargin && <div className="md:mb-[88px] mb-[72px]"></div>}
        <Popover className={`w-full fixed top-0 z-[9999999999] ${ (!hasHero || scrolled) && "bg-white shadow-lg" }`}>
            {({ open }) => (
                <>
                    <div className="max-w-[1440px] mx-auto px-4 sm:px-6">
                        <div className={`flex justify-between items-center lg:justify-start lg:space-x-10 transition-all duration-300 ${scrolled ? 'lg:py-2 py-4' : 'lg:py-6 py-6'}`}>
                            <div className="flex justify-start items-center lg:flex-1">
                                <Link to="/">
                                <span className="sr-only">Summit Training</span>
                                <img
                                    className="w-28"
                                    src={Logo}
                                    alt="Summit Training Logo"
                                />
                                </Link>
                            </div>
                            {!open && (
                                <div className="-mr-2 -my-2 lg:hidden focus:outline-none outline-none" onClick={() => setScrollLock(!scrollLock)} onKeyDown={() => setScrollLock(!scrollLock)} role="button" tabIndex="0">
                                    <Popover.Button className={`p-2 inline-flex items-center justify-center focus:outline-none outline-none ${ hasHero && !scrolled ? "text-white" : "text-darkblue" }`}>
                                    <span className="sr-only">Open menu</span>
                                    <MenuAlt1Icon className="h-6 w-6 focus:outline-none outline-none" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            )}
                            <ScrollLock isActive={scrollLock} />
                            <Popover.Group static as="nav" className="hidden lg:flex space-x-4 items-center">
                                {settings.header_navigation.map((item, index) => {
                                    if (item.children.length > 0) {
                                        return (
                                            <SbEditable content={item} key={item._uid}>
                                                <HeaderDesktopItem {...item} hasHero={hasHero} scrolled={scrolled} heroColour={heroColour}/>
                                        </SbEditable>
                                        )
                                    } else {
                                        return (
                                            <SbEditable content={item} key={item._uid}>
                                                <Link 
                                                    key={index}
                                                    to={`${item.target.cached_url === "home" ? "" : resolveLink(item.target.cached_url)}`} 
                                                    className={`text-[0.9375rem] font-body font-semibold leading-none duration-300 p-3 ${ hasHero && !scrolled ? "text-white" : "text-darkblue" } ${heroColour === 'pink' ? 'hover:text-darkblue' : 'hover:text-pink'}`}>
                                                    {item.name}
                                                </Link>
                                            </SbEditable>
                                        )
                                    }
                                })}

                            </Popover.Group>
                            <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
                                <Button to="contact-us" type="pink" className="!px-3 !py-1 !text-base">Contact</Button>
                            </div>
                        </div>
                    </div>

                    {/* Mobile menu starts here */}
                    <Transition

                        enter="duration-300 ease-in-out"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="duration-300 ease-in-out"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Popover.Panel static className="absolute top-0 inset-x-0 lg:hidden z-20 h-screen overflow-hidden">
                            <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-pink divide-y-2 divide-gray-50 h-screen">
                                <div className={`sm:px-6 px-4 ${scrolled ? 'py-2' : 'py-4'} flex flex-col items-center justify-between h-full`}>
                                    <div className="w-full h-full overflow-y-scroll overflow-x-hidden">
                                        <div className="flex items-center justify-between w-full mb-6">
                                            <div>
                                                <img
                                                    className="w-28"
                                                    src={LogoWhite}
                                                    alt="Summit Training Logo"
                                                />
                                            </div>
                                            <div className="-mr-2 focus:outline-none outline-none" onClick={() => setScrollLock(!scrollLock)} onKeyDown={() => setScrollLock(!scrollLock)} role="button" tabIndex="0">
                                            <Popover.Button className="bg-pink p-2 inline-flex items-center justify-center text-white focus:outline-none outline-none">
                                                <span className="sr-only">Close menu</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                            </div>
                                        </div>

                                        <TouchScrollable>
                                            <div className="w-full h-[calc(100%-72px)] flex flex-col justify-center overflow-y-scroll overflow-x-hidden">
                                                <nav className="grid">
                                                    {settings.header_navigation.map((item) => {
                                                        if (item.children.length > 0) {
                                                            return (
                                                                <SbEditable content={item} key={item._uid}>
                                                                    <HeaderMobileItem {...item} scrolled={scrolled}/>
                                                                </SbEditable>
                                                            )
                                                        } else {
                                                            return (
                                                                <SbEditable content={item} key={item._uid}>
                                                                    <Link 
                                                                        to={`${item.target.cached_url === "home" ? "" : resolveLink(item.target.cached_url)}`} 
                                                                        className="text-xl font-body font-semibold leading-6 text-white duration-300 py-3">
                                                                        {item.name}
                                                                    </Link>
                                                                </SbEditable>
                                                            )
                                                        }
                                                    })}
                                                </nav>
                                            </div>
                                        </TouchScrollable>
                                    </div>
                                    <Button to="contact-us" type="darkblue" className="w-full text-center justify-self-end mt-6">Contact</Button>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    </SbEditable>
  )
}

export default Navigation;
