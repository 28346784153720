import React from 'react'
import Heading from 'src/components/Heading'
import { Link } from 'gatsby'
import resolveLink from 'src/utils/resolveLink'

const TwoColumnWithSignUpItem = (blok) => {
    const date  = new Date(blok.first_published_at).toLocaleDateString("da-DK")
    const archiveContent = blok.isArchive ? JSON.parse(blok.content) : ''
    return (
        <Link to={resolveLink(blok.full_slug)} className="group">
            {(blok.isArchive || blok.content.component === 'post') && <time dateTime={date} className="text-pink mb-2 font-body">{date}</time>}
            <Heading size="h3" className="text-darkblue group-hover:text-pink duration-300 mt-2">{blok.name}</Heading>
            <p className="prose leading-6 text-gray font-body">{blok.isArchive ? archiveContent.description : blok.content.description}</p>
            <div className={`font-display font-black text-lg leading-6 uppercase italic cursor-pointer inline-block pr-6 py-3 line-crop tracking-[0.03em] transition duration-300 text-pink pl-0 mt-3`}>
                <i className={`far fa-long-arrow-right pr-1 text-xl`}></i> Read more
            </div>
        </Link>
    )
};

export default TwoColumnWithSignUpItem;