module.exports = {
  siteMetadata: {
    title: "Summit Training",
    author: {
      name: "Summit Training",
      // summary: 'TBB Gatsby / Storyblok',
    },
    description: "Achieve so much more with summit.",
    siteUrl: "https://www.summittraining.co.uk/",
    image: "https://a.storyblok.com/f/129040/1200x627/f63ebcde90/meta.jpg",
  },
  plugins: [
    "gatsby-plugin-postcss",
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "Bj3f7aI0gWfW34nIHMcDwwtt",
        homeSlug: "home",
        version: process.env.NODE_ENV === "production" ? "published" : "draft",
      },
    },
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: "GTM-K85MRP",
        includeInDevelopment: false,
        defaultDataLayer: { platform: "gatsby" },
        enableWebVitalsTracking: true,
      },
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: [
          "UA-9162470-1", // Google Analytics / GA
          "G-3S8266GFQG",
        ],
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: true,
        },
      },
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://www.summittraining.co.uk',
        sitemap: 'https://www.summittraining.co.uk/sitemap/sitemap-0.xml',
        policy: [{ userAgent: '*', allow: '/' }],
      },
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /images/,
        },
      },
    },
    "gatsby-plugin-root-import",
    "gatsby-plugin-react-helmet",
    {
      resolve: `gatsby-plugin-netlify`,
      options: {
        headers: {
          "/*": [
            "Cache-Control: public",
            "Cache-Control: max-age=31536000",
            "Content-Security-Policy: frame-ancestors 'self' https://*.storyblok.com/",
            "X-Frame-Options: ALLOW-FROM https://app.storyblok.com/",
          ],
        },
      },
    },
    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: `https://www.summittraining.co.uk`,
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Summit Training`,
        short_name: `Summit Training`,
        start_url: `/`,
        icon: `static/summit-training-favicon.png`,
      },
    },
    "gatsby-plugin-sitemap",
    "gatsby-plugin-force-trailing-slashes",
  ],
};
