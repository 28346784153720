import React from "react"
import Link from 'gatsby-link'
import SbEditable from 'storyblok-react'
import LogoWhite from '/static/summit_logo_white.svg'
import FixedCta from './FixedCta'
import resolveLink from 'src/utils/resolveLink'

const Footer = ({ settings }) => {
  return (
    <footer className="bg-darkblue text-white">
      <div className="max-w-7xl mx-auto py-14 px-4 overflow-hidden sm:px-6 lg:px-8 flex flex-col justify-center items-center">
      <a href="/" className="flex flex-col items-center">
          <span className="sr-only">Summit Training</span>
          <img
              className="w-28"
              src={LogoWhite}
              alt="Summit Training Logo"
          />
          <span className="font-body mt-2">Reach your potential</span>
        </a>
        <nav className="flex flex-wrap justify-center my-6" aria-label="Footer">
          {settings.content.footer_navigation.map((item) => (
            <SbEditable content={item} key={item.name}>
              <Link to={`${item.target.cached_url === "home" ? "" : resolveLink(item.target.cached_url)}`} key={item.name} className="p-3 text-[0.9375rem] font-body font-semibold leading-none hover:text-pink duration-300">
                {item.name}
              </Link>
            </SbEditable>
          ))}
        </nav>
        <p className="font-body text-sm mb-1 text-center">Summit Consulting and Training Ltd, 33 Harrison Road, Halifax HX1 2AF</p>
        <p className="font-body text-sm text-center"><a href="tel:0845 052 3701" className="hover:text-pink font-semibold duration-300 mr-2">0845 052 3701</a> | <a href="mailto:info@summittraining.co.uk" className="hover:text-pink font-semibold duration-300 ml-2">info@summittraining.co.uk</a></p>
        <div className="flex flex-row justify-center items-center mt-4 mb-6 text-xl gap-4">
          <a href="https://www.facebook.com/teamsummittraining/" target="_blank" rel="noreferrer" className="hover:text-pink duration-300 p-2"><span className="sr-only">Summit Training Facebook</span><i className="fab fa-facebook"></i></a>
          <a href="https://twitter.com/thescottwatson/" target="_blank" rel="noreferrer" className="hover:text-pink duration-300 p-2"><span className="sr-only">Summit Training Twitter</span><i className="fab fa-twitter"></i></a>
          <a href="https://www.linkedin.com/company/summit-consulting-and-training-limited/" target="_blank" rel="noreferrer" className="hover:text-pink duration-300 p-2"><span className="sr-only">Summit Training LinkedIn</span><i className="fab fa-linkedin-in"></i></a>
        </div>
        <p className="text-xs text-center font-body"><span className="opacity-30">©️ Summit Consulting and Training Ltd 2021. Website Design & Development by</span> <a href="https://www.thebiggerboat.co.uk/" className="opacity-30  hover:opacity-100 duration-300">The Bigger Boat</a></p>
      </div>
      <FixedCta/>
    </footer>
  )
}

export default Footer
