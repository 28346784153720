import React, { useState, useEffect } from 'react'
import SbEditable from "storyblok-react"
import Button from "src/components/Button"
import Heading from 'src/components/Heading'
import { BackgroundColour, TitleColour, ArrowButtonColour } from "src/utils/ColourUtilities"

const Hero = ({ blok }) => {

    const words = blok.title.split(' ').filter(item => { return item !== "" })
    const [state, setState] = useState(false)

    useEffect(() => {
        setState(true)
    }, [state])

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`relative h-[80vh] ${BackgroundColour(blok.colour)}`}>
                <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8 flex flex-col justify-center items-start h-full">
                    <h1 className={`font-display 2xl:text-[9rem] xl:text-[7rem] lg:text-[5rem] md:text-7xl sm:text-6xl text-5xl 2xl:leading-[8rem] xl:leading-[6rem] lg:leading-[4.5rem] md:leading-[4rem] sm:leading-[3rem] leading-[3rem] font-black italic uppercase ${TitleColour(blok.colour)}`}>
                        {blok.title}
                    </h1>
                    { blok.button_text && blok.button_target.cached_url 
                        ? <Button to={blok.button_target.cached_url} className={`${ArrowButtonColour(blok.colour)} mt-10`} arrow>{blok.button_text}</Button>
                        : <Heading size="h4" className={`uppercase mt-10 ${ArrowButtonColour(blok.colour, true)}`}>{blok.button_text}</Heading>
                    }
                </div>
            </div>
        </SbEditable>
    )
}

export default Hero
