import React from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import LogoDarkblue from '/static/summit_logo_darkblue.svg'
import Button from "src/components/Button"
import { Link } from 'gatsby'
import SbEditable from 'storyblok-react'
import { TouchScrollable } from 'react-scrolllock';
import resolveLink from 'src/utils/resolveLink'

const HeaderMobileItem = (item) => {

    return (
        <Popover className={`w-full`}>
            {({ openInner }) => (
                <>
                {!openInner && (
                <Popover.Button 
                    as="div"
                    className={`text-xl font-body font-semibold leading-6 text-white duration-300 py-3 flex justify-between items-center w-full cursor-pointer`}>
                    <span>{item.name}</span> 
                    <ChevronRightIcon className="h-5 w-5 -ml-1 mr-2" aria-hidden="true" />
                </Popover.Button>
                )}
                <Transition
                    as={Fragment}
                    enter="duration-300 ease-in-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-300 ease-in-out"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Popover.Panel className="absolute top-0 inset-x-0 lg:hidden z-20 h-screen overflow-hidden">
                    <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 h-screen">
                        <div className={`sm:px-6 px-4 ${item.scrolled ? 'py-2' : 'py-4'} flex flex-col items-center justify-between h-full`}>
                            <div className="w-full h-full overflow-y-scroll overflow-x-hidden">
                                <div className="flex items-center justify-between w-full mb-6">
                                    <div>
                                        <img
                                            className="w-28"
                                            src={LogoDarkblue}
                                            alt="Summit Training Logo"
                                        />
                                    </div>
                                    <div className="-mr-2 focus:outline-none outline-none h-10" role="button" tabIndex="0">
                                    <Popover.Button className="bg-white p-3 pl-0 mb-4 inline-flex items-center justify-start text-darkblue focus:outline-none outline-none text-lg font-body font-semibold">
                                        <ChevronLeftIcon className="h-5 w-5 -ml-1 mr-2" aria-hidden="true" />
                                        <span className="mb-[-2px]">{item.name}</span>
                                    </Popover.Button>
                                    </div>
                                </div>

                                <TouchScrollable>
                                    <div className="w-full h-[calc(100%-72px)] flex flex-col justify-center overflow-y-scroll">
                                        {item.children.map((item, index) => (
                                            <SbEditable content={item} key={item._uid}>
                                                <Link 
                                                    key={index}
                                                    to={`${item.target.cached_url === "home" ? "" : resolveLink(item.target.cached_url)}`} 
                                                    className="text-xl font-body leading-6 text-darkblue duration-300 py-3 block">
                                                    <span className='font-semibold'>{item.name}</span>
                                                    <p className="text-sm text-gray font-body">{item.description}</p>
                                                </Link>
                                            </SbEditable>
                                        ))}
                                    </div>
                                </TouchScrollable>
                            </div>
                        <Button to="contact-us" type="darkblue" className="w-full text-center mt-6">Contact</Button>
                        </div>
                    </div>
                    </Popover.Panel>
                </Transition>
                </>
            )}
            </Popover>
    )
}

export default HeaderMobileItem